import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function ActualityCard({ actuality, isLatest, initiallyExpanded, onEdit, handleLogout }) {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const [transitionCompleted, setTransitionCompleted] = useState(true);
  const infoRef = useRef(null);
  const isAuthenticated = !!localStorage.getItem('token');

  useEffect(() => {
    const hash = window.location.hash;
    const shouldBeExpanded = hash === `#actuality-${actuality.id}`;
    if (shouldBeExpanded) {
      setIsExpanded(true);
      if (infoRef.current) {
        const fullHeight = `${infoRef.current.scrollHeight + 300}px`;
        infoRef.current.style.maxHeight = fullHeight;
        setTimeout(() => {
          if (infoRef.current) {
            const elementPosition = infoRef.current.getBoundingClientRect().top + window.pageYOffset; // Position de l'élément par rapport au haut du document
            const offsetPosition = elementPosition - 140; // Décale la position pour laisser un espace en haut
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' }); // Scrolle jusqu'à la position ajustée
          }
        }, 100); // Un délai court pour permettre la mise à jour du DOM
      }
    }
  }, [actuality.id], [initiallyExpanded]);
  
  const toggleExpansion = () => {
    if (infoRef.current) {
      const fullHeight = `${infoRef.current.scrollHeight + 300}px`;  // Hauteur totale du contenu plus un ajustement
      if (!isExpanded) {
        // Transition vers la pleine hauteur
        infoRef.current.style.maxHeight = fullHeight;
        setIsExpanded(true);
        setTransitionCompleted(true); // Assurez-vous que le flex-direction est column immédiatement lors de l'expansion
        setTimeout(() => {
          if (infoRef.current) {
            const elementPosition = infoRef.current.getBoundingClientRect().top + window.pageYOffset; // Position de l'élément par rapport au haut du document
            const offsetPosition = elementPosition - 140; // Décale la position pour laisser un espace en haut
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' }); // Scrolle jusqu'à la position ajustée
          }
        }, 100); // Un délai court pour permettre la mise à jour du DOM
      } else {
        // Attendre la fin de l'animation pour changer flex-direction
        setTransitionCompleted(false);
        infoRef.current.style.maxHeight = '200px';  // Hauteur minimale en rétraction
        setIsExpanded(false);
        setTimeout(() => {
          setTransitionCompleted(true); // Remet le flex-direction à son état initial après la transition
        }, 150);  // Assurez-vous que ce délai correspond à la durée de votre transition CSS
      }
    }
  };

  // Générer une clé unique à chaque bascule pour réinitialiser l'animation
  const uniqueKeyForImg = `img-${actuality.id}-${isExpanded ? 'expanded' : 'not-expanded'}`;
  const uniqueKeyForInfo = `info-${actuality.id}-${isExpanded ? 'expanded' : 'not-expanded'}`;

  const handleDelete = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
      try {
        const response = await fetch(`https://api.gemenu.fr/api/articles/${actuality.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          window.location.reload();
        } else {
          if (response.status === 403) {
            handleLogout('expired'); // Déconnecter l'utilisateur si le token est expiré
            return;
          }
          console.error("Failed to delete article.");
        }
      } catch (error) {
        console.error("Error deleting article:", error);
      }
    }
  };

  // Fonction utilitaire pour reformater la date
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  
  // Tableau des noms de mois en français
  const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  
  // Récupérer le nom du mois correspondant
  const monthName = months[parseInt(month, 10) - 1];

  // Vérifier si c'est le premier jour du mois
  const dayFormatted = day === '01' ? '1er' : day;

  return `${dayFormatted} ${monthName} ${year}`;
};

  return (
    <div ref={infoRef} className={`actuality_card ${isLatest ? 'most-recent' : ''} ${isExpanded ? 'expanded' : ''} ${transitionCompleted ? '' : 'transitioning'}`} id={`actuality-${actuality.id}`}>
      {actuality.banner && (
      <img
        key={uniqueKeyForImg + '-banner'}
        src={`https://api.gemenu.fr${actuality.banner}`}
        alt="Banner"
        className={`banner-image ${isExpanded ? 'expanded' : ''}`}
      />
      )}
      <div key={uniqueKeyForInfo} className={`actuality_card_info ${isExpanded ? 'expanded' : ''}`}>
        <h2>{actuality.title}</h2>
        <span className="actuality_card_info_date">{formatDate(actuality.date)}</span>
        <span className={`actuality_card_tag ${isExpanded ? 'expanded' : ''}`}>{actuality.tag}</span>
        {Array.isArray(actuality.content) && actuality.content.map((block, index) => {
          if (block.type === 'image') {
            return (
              <img
                key={uniqueKeyForImg + index}
                src={`https://api.gemenu.fr${block.src}`}
                alt={block.alt}
                className="actuality_card_info_image"
              />
            );
          } else if (block.type === 'section') {
            return (
              <div key={index} className="actuality_card_info_section">
                {block.title && <h4 className="actuality_card_section_title">{block.title}</h4>}
                {block.date && <span className="actuality_card_section_date">{block.date}</span>}
                <p className="actuality_card_section_content">{block.content || block.value}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
      {isLatest && <span className="actuality_card_latest">Dernière actualité</span>}
      {isAuthenticated && (
        <div className="admin_controls">
          <FontAwesomeIcon icon={faEdit} onClick={onEdit} />
          <FontAwesomeIcon icon={faTrash} onClick={handleDelete} />
        </div>
      )}
      <FontAwesomeIcon icon={faChevronDown} onClick={toggleExpansion} className={`${isExpanded ? 'expanded' : ''}`} />
    </div>
  );
}

export default ActualityCard;