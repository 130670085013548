import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../Utils/Button';
import Separator from '../Utils/Separator';
import FormationForm from '../FormationForm/FormationForm';

function FormationAbout() {
  const [modalVisible, setModalVisible] = useState(false);

  // Fonction pour ouvrir la modale
  const openModal = () => {
    setModalVisible(true);
  };
  // Fonction pour fermer la modale
  const closeModal = () => setModalVisible(false);

  return (
    <div className="formation_about" id='about'>
      <h1 className='formation_about_title'>Notre formation</h1>
      <p className='formation_about_intro'>Transformez votre expertise en gériatrie et gérontologie avec notre programme exclusif certifié par l'Université Sorbonne.</p>
      <div className='formation_about_content'>
        <h3>Formation en Gériatrie et Gérontologie</h3>
        <p>En collaboration avec l'Université Sorbonne</p>
        <Separator />
        <ul>
          <h4>Première Année: Cours International de Médecine Gériatrique à Distance (CIMG)</h4>
          <li><FontAwesomeIcon icon={faCircleCheck} /> Formation en ligne complète : Flexibilité et accessibilité où que vous soyez.</li>
          <li><FontAwesomeIcon icon={faCircleCheck} /> Fondamentaux en gériatrie : Comprendre les défis du vieillissement.</li>
          <li><FontAwesomeIcon icon={faCircleCheck} /> Certification reconnue : Diplôme universitaire après la première année.</li>
        </ul>
        <Separator />
        <ul>
          <h4>Deuxième Année: Master 2 Expertise en Gérontologie</h4>
          <li><FontAwesomeIcon icon={faCircleCheck} /> Formation en présentiel : Immersion à l’Université Sorbonne.</li>
          <li><FontAwesomeIcon icon={faCircleCheck} /> Compétences avancées : Gestion de cas complexes et projets de recherche.</li>
          <li><FontAwesomeIcon icon={faCircleCheck} /> Expérience pratique : Stages et études de cas sur des patients réels.</li>
        </ul>
        <Button content="S'inscrire à la formation" onClick={openModal}/>
      </div>
      <FormationForm closeModal={closeModal} modalVisible={modalVisible}/>
    </div>
  );
}

export default FormationAbout;