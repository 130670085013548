import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Separator from '../Utils/Separator';

const partners = [
  {id: 1, logo: 'assets/partners_pictures/logo_min_rsi.png'},
  {id: 2, logo: 'assets/partners_pictures/logo_min_esu.png'},
  {id: 3, logo: 'assets/partners_pictures/logo_min_sante.png'},
  {id: 4, logo: 'assets/partners_pictures/logo_sorbonne.png'},
  {id: 5, logo: 'assets/partners_pictures/inrb_logo.png'},
  {id: 6, logo: 'assets/partners_pictures/logo_croix_rouge.png'},
  {id: 7, logo: 'assets/partners_pictures/lesetoiles.png'},
  {id: 8, logo: 'assets/partners_pictures/logo_unic_bukavu.png'},
  {id: 9, logo: 'assets/partners_pictures/logo_unimba.jpg'},
  {id: 10, logo: 'assets/partners_pictures/m_logo.png'},
  {id: 11, logo: 'assets/partners_pictures/logo_uni_kindu.png'},
  {id: 12, logo: 'assets/partners_pictures/logo_unikis.png'},
  {id: 13, logo: 'assets/partners_pictures/logo_unilu.png'},
  {id: 14, logo: 'assets/partners_pictures/sultani_river.png'},
  {id: 15, logo: 'assets/partners_pictures/uni_logo_kongo.png'},
  {id: 16, logo: 'assets/partners_pictures/unikin_logo2.png'},
  {id: 17, logo: 'assets/partners_pictures/ongd_chanty_bah.png'},
];

function Partners() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='partners'>
      <Separator />
      <h2 className='partners_title'>Nos partenaires</h2>
      <Slider {...settings}>
        {partners.map(partner => (
          <div key={partner.id} className="partner">
            <img src={partner.logo} alt={`Logo de nos partenaires`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Partners;