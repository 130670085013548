import React from 'react';
import CongresAbout from '../../components/CongresAbout/CongresAbout';

function Congres(){

  return (
    <div className='congres'>
      <CongresAbout />

    </div>
  )
}

export default Congres;