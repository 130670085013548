import { React, useState, useEffect } from 'react';
import LastActualityCard from '../LastActualityCard/LastActualityCard';
import Separator from '../Utils/Separator';


function LastActuality() {

  const [actualities, setActualities] = useState([]);

  useEffect(() => {
    const fetchActualities = async () => {
      try {
        const response = await fetch('https://api.gemenu.fr/api/articles');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const lastThreeActualities = data
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);
        setActualities(lastThreeActualities);
      } catch (error) {
        console.error('Erreur lors du chargement des actualités:', error);
      }
    };

    fetchActualities();
  }, []);

  return (
    <div className='last-actuality'>
      <Separator />
      <h2>Dernières actualités</h2>
      <div className='last-actuality_cards'>
        {actualities.map(actuality => (
          <LastActualityCard
            key={actuality.id}
            id={actuality.id}
            title={actuality.title}
            image={`https://api.gemenu.fr${actuality.banner}`}
            tag={actuality.tag}
            date={actuality.date}
          />
        ))}
      </div>
    </div>
  );
}

export default LastActuality;