import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Card({ title, description, icon }) {
  return (
    <div className="about_card">
      <h3>{title}</h3>
      <FontAwesomeIcon icon={icon} />
      <p>{description}</p>
    </div>
  );
}

export default Card;