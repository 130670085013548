import React from 'react';
import Card from '../Card/Card';
import Separator from '../Utils/Separator';
import { faHandshake, faLightbulb, faVials, faEarthAfrica, faGraduationCap, faSyringe } from '@fortawesome/free-solid-svg-icons';

function About() {
  return (
    <div className="about">
  <Separator />
  <h2 id="about">À propos de Gemenu</h2>
  <div className="about_cards">
  <Card
    icon={faHandshake}
    title='Collaboration'
    description='Nous facilitons les échanges entre professionnels de santé de l’Occident et de l’Afrique, favorisant le partage de connaissances en gériatrie et gérontologie.'
  />
  <Card 
    icon={faLightbulb}
    title='Innovation'
    description='Gemenu initie des projets innovants pour révolutionner la gestion des soins de santé et promouvoir des talents à travers des séminaires et congrès en RDC.'
  />
  <Card
    icon={faVials}
    title='Imagerie & Oncologie'
    description='Nous préparons le lancement de formations avancées en imagerie médicale et oncologie pour répondre aux besoins critiques de compétences spécialisées.'
  />
  <Card
    icon={faEarthAfrica}
    title='Impact Social'
    description="Notre mission est d'améliorer la qualité de vie en Afrique, en soutenant le développement médical et en offrant des opportunités de formation avancée."
  />
  <Card
    icon={faGraduationCap}
    title='Formation Flexible'
    description='Nous proposons des formations flexibles permettant aux étudiants de commencer leur apprentissage sans attendre la disponibilité complète des fonds.'
  />
  <Card
    icon={faSyringe}
    title='Prévention'
    description='Nous intensifions nos efforts pour sensibiliser et éduquer sur les principales menaces de santé, augmentant les initiatives de prévention sur tout le continent.'
  />
</div>
</div>
  );
}

export default About