import React from 'react';
import FormationAbout from '../../components/FormationAbout/FormationAbout';

function Formation() {
  return (
    <div>
      <FormationAbout />
    </div>
  );
}

export default Formation;