import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import AddressAutocomplete from './AddressAutocomplete/AddressAutocomplete';

function FormationForm({ closeModal, modalVisible }) {

  // Gestion du clic en dehors de la modale pour fermer
  const handleOutsideClick = (event) => {
    if (event.target.id === 'modal') {
      closeModal();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Pour éviter le rechargement de la page
    const form = event.target;

    const formData = {
      nom: form.nom.value,
      prenom: form.prenom.value,
      email: form.email.value,
      telephone: form.telephone.value,
      dob: form.dob.value,
      adresse: form.adresse.value,
      universite: form.universite.value,
      anneeDiplome: form.anneeDiplome.value,
      typeMedecin: form.typeMedecin.value,
      specialisation: form.specialisation.value
    };

    // Envoi des données du formulaire à l'API
    try {
      const response = await fetch('https://api.gemenu.fr/api/forms/submit-formation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      if (!response.ok) {
        const errorResponse = await response.json(); // ou response.json() si le serveur renvoie du JSON
        console.error('Erreur serveur:', errorResponse);
        throw new Error(`Réponse du serveur non OK: ${response.status}`);
      }
  
      const data = await response.json(); // Assurez-vous que la réponse est en JSON
      alert(data.message); // Affichez le message de succès
      closeModal(); // Fermez la modale si tout est bon
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      alert("Erreur lors de la soumission du formulaire. Veuillez réessayer.");
    }
  };

  return (
    <div className="formation_form" id="modal" onClick={handleOutsideClick} style={{
      opacity: modalVisible ? '1' : '0',
      visibility: modalVisible ? 'visible' : 'hidden'
    }}>
      <form id="registrationForm" onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit}>
        <div className='formation_form_header'>
          <FontAwesomeIcon icon={faXmark} className='close' onClick={closeModal}/>
          <h2>Formulaire d'inscription</h2>
        </div>
        <label htmlFor="nom">Nom:</label>
        <input type="text" id="nom" name="nom" required autoComplete="given-name"/>

        <label htmlFor="prenom">Prénom:</label>
        <input type="text" id="prenom" name="prenom" required autoComplete="on"/>

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required autoComplete="on"/>

        <label htmlFor="telephone">Téléphone:</label>
        <input type="tel" id="telephone" name="telephone" required autoComplete="on"/>

        <label htmlFor="dob">Date de naissance:</label>
        <input type="date" id="dob" name="dob" required autoComplete="on"/>

        <label htmlFor="adresse">Adresse postale:</label>
        <AddressAutocomplete id="adresse" name="adresse" required/>

        <label htmlFor="universite">Université d'origine:</label>
        <input type="text" id="universite" name="universite" required autoComplete="on"/>

        <label htmlFor="anneeDiplome">Année d'obtention du diplôme:</label>
        <select id="anneeDiplome" name="anneeDiplome" required>
          <option value="">Sélectionnez une année</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
          <option value="2014">2014</option>
          <option value="2013">2013</option>
          <option value="2012">2012</option>
          <option value="2011">2011</option>
          <option value="2010">2010</option>
          <option value="2009">2009</option>
          <option value="2008">2008</option>
          <option value="2007">2007</option>
          <option value="2006">2006</option>
          <option value="2005">2005</option>
          <option value="2004">2004</option>
          <option value="2003">2003</option>
          <option value="2002">2002</option>
          <option value="2001">2001</option>
          <option value="2000">2000</option>
          <option value="1999">1999</option>
          <option value="1998">1998</option>
          <option value="1997">1997</option>
          <option value="1996">1996</option>
          <option value="1995">1995</option>
          <option value="1994">1994</option>
          <option value="1993">1993</option>
          <option value="1992">1992</option>
          <option value="1991">1991</option>
          <option value="1990">1990</option>
          <option value="1989">1989</option>
          <option value="1988">1988</option>
          <option value="1987">1987</option>
          <option value="1986">1986</option>
          <option value="1985">1985</option>
          <option value="1984">1984</option>
          <option value="1983">1983</option>
          <option value="1982">1982</option>
          <option value="1981">1981</option>
          <option value="1980">1980</option>
        </select>

        <label htmlFor="typeMedecin">Médecin généraliste ou spécialisé ? *</label>
        <select id="typeMedecin" name="typeMedecin" required>
          <option value="">Sélectionnez le type</option>
          <option value="generaliste">Généraliste</option>
          <option value="specialise">Spécialisé</option>
        </select>

        <label htmlFor="specialisation">Si vous êtes spécialisé, précisez votre spécialisation:</label>
        <input type="text" id="specialisation" name="specialisation" autoComplete="on"/>

        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}

export default FormationForm;