import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

// Charger la clé publique de Stripe
const stripePromise = loadStripe('pk_live_51PSHZ4CZ7KVaLWVC7RVRApDg9XxnHgSggatxPORWZSdhTmpP7lcT8WUMGn9Z5rDLJBqprOmSjFvw7XtlQuafZFYH00rYWYHSYW');

const CheckoutForm = ({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        backgroundColor: '#f6f6f6',
        margin: '10px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  // Gestion des champs de saisie
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    status: 'etudiant', // 'etudiant' ou 'professionnel'
    profession: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    const response = await fetch('https://api.gemenu.fr/paiement/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData), // Envoyer les données utilisateur sans le montant
    });

    const { clientSecret } = await response.json();

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          phone: formData.phone,
        },
      },
    });

    if (result.error) {
      setError(`Paiement échoué: ${result.error.message}`);
      setIsProcessing(false);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setSucceeded(true);
        setError(null);
        setIsProcessing(false);
        alert('Paiement réussi! Vous êtes inscrit au congrès.');
        onClose(); // Fermer la modale après le succès du paiement
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>

        <label>Prénom:</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />

        <label>Nom:</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />

        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Téléphone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <label>Status:</label>
        <select
          name="status"
          value={formData.status}
          onChange={handleChange}
          required
        >
          <option value="etudiant">Étudiant</option>
          <option value="professionnel">Professionnel</option>
        </select>
      {formData.status === 'professionnel' && (
        <>
          <label>Profession:</label>
          <input
            type="text"
            name="profession"
            value={formData.profession}
            onChange={handleChange}
            required={formData.status === 'professionnel'}
          />
        </>
      )}
      <CardElement options={cardElementOptions}/>
      <button disabled={!stripe || isProcessing || succeeded} type="submit">
        {isProcessing ? 'Traitement...' : "S'inscrire (20€)"}
      </button>
      {error && <div>{error}</div>}
      {succeeded && <div>Paiement réussi !</div>}
    </form>
  );
};

const CongresRegistrationModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="congres-modal-overlay">
      <div className="congres-modal-content" style={{
      opacity: isOpen ? '1' : '0',
      visibility: isOpen ? 'visible' : 'hidden'
      }}>
        <FontAwesomeIcon icon={faXmark} className='close' onClick={onClose}/>
        <h1>Inscription au Congrès</h1>
        <p>Veuillez remplir le formulaire ci-dessous pour vous inscrire au congrès. Un paiement de 20€ est requis pour finaliser l'inscription.</p>
        <Elements stripe={stripePromise}>
          <CheckoutForm onClose={onClose} />
        </Elements>
      </div>
    </div>
  );
};

const CongresRegistration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button id="inscription" onClick={openModal}>S'inscrire !</button>
      <CongresRegistrationModal className="Stripe"isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default CongresRegistration;