function MentionLegales() {
  return (
    <div className="privacy-and-legal">
      <section id="privacy-policy">
        <h1>Politique de confidentialité</h1>
        <h2>Introduction</h2>
        <p>L'association Gemenu, œuvrant dans le domaine de la santé en Afrique, est soucieuse de la protection de vos données personnelles. Cette politique de confidentialité décrit les types de données personnelles que nous collectons, comment nous les utilisons, et les mesures que nous prenons pour assurer leur protection.</p>
        
        <h2>Données collectées</h2>
        <p>Nous collectons les données personnelles suivantes : nom, prénom, email, téléphone, date de naissance, adresse postale, université d'origine, année d'obtention du diplôme, statut de médecin généraliste ou spécialisé, et, le cas échéant, la spécialité du médecin.</p>
        
        <h2>Utilisation des données</h2>
        <p>Les données collectées sont utilisées pour inscrire nos utilisateurs à des formations de santé reconnues, gérer les inscriptions, communiquer avec les utilisateurs, et améliorer nos services.</p>
        
        <h2>Partage des données</h2>
        <p>Vos données personnelles peuvent être partagées avec nos partenaires de formation dans le cadre de l'organisation des formations. Nous ne vendons ni ne louons vos données à des tiers.</p>
        
        <h2>Sécurité des données</h2>
        <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non autorisé, altération, divulgation ou destruction.</p>
        
        <h2>Vos droits</h2>
        <p>Vous avez le droit d'accéder, de rectifier, de supprimer vos données personnelles, et de vous opposer à leur traitement. Pour exercer ces droits, veuillez nous contacter à <a href="mailto:contact.gemenu@gmail.com">contact.gemenu@gmail.com</a>.</p>
        
        <h2>Durée de conservation</h2>
        <p>Vos données personnelles sont conservées pendant la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées, ou conformément aux exigences légales.</p>
        
        <h2>Modifications de cette politique</h2>
        <p>Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Toute modification sera publiée sur cette page, et votre utilisation continue de nos services après ces modifications constitue votre acceptation de la politique mise à jour.</p>
        
        <h2>Contact</h2>
        <p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à <a href="mailto:contact.gemenu@gmail.com">contact.gemenu@gmail.com</a>.</p>
      </section>
    
      <section id="legal-notices">
        <h1>Mentions légales</h1>
        <h2>Informations sur l'association</h2>
        <p>Le site web Gemenu est exploité par l'association Gemenu, dont le siège social est situé au 26 rue MOUCHOTTE 75014, Paris, France. Pour toute correspondance, vous pouvez nous contacter par email à <a href="mailto:contact.gemenu@gmail.com">contact.gemenu@gmail.com</a>.</p>
        
        <h2>Propriété intellectuelle</h2>
        <p>Tous les contenus présents sur ce site, y compris textes, images, graphiques et logos, sont la propriété de Gemenu ou de ses partenaires et sont protégés par les lois sur la propriété intellectuelle. Toute reproduction, distribution ou utilisation non autorisée de ces contenus est interdite.</p>
        
        <h2>Responsabilité</h2>
        <p>Les informations fournies sur ce site sont à titre informatif et ne constituent pas des conseils médicaux ou professionnels. Gemenu ne saurait être tenue responsable des dommages résultant de l'utilisation de ces informations.</p>
        
        <h2>Liens vers des sites tiers</h2>
        <p>Ce site peut contenir des liens vers des sites externes qui ne sont pas gérés par Gemenu. Nous ne sommes pas responsables du contenu de ces sites ni des pratiques de confidentialité de ces sites tiers.</p>
        
        <h2>Loi applicable</h2>
        <p>Les présentes mentions légales sont régies par la loi Française. En cas de litige, les tribunaux Français seront seuls compétents.</p>
        
        <h2>Contact</h2>
        <p>Pour toute question légale ou toute réclamation, veuillez nous contacter à <a href="mailto:contact.gemenu@gmail.com">contact.gemenu@gmail.com</a>.</p>
      </section>
    </div>
  );
}

export default MentionLegales;