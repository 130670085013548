import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

function ActualityModal({ isOpen, onClose, onSave, setInitialData, initialData }) {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [content, setContent] = useState([]);
  const [tag, setTag] = useState('');
  const [bannerFile, setBannerFile] = useState(null);

  const textareasRef = useRef([]);

  // Fonction pour ajuster la hauteur des textareas
  const adjustHeight = () => {
    requestAnimationFrame(() => {
      textareasRef.current.forEach(textarea => {
        if (textarea) {
          textarea.style.height = 'auto'; // Réinitialiser la hauteur
          textarea.style.height = `${textarea.scrollHeight}px`; // Ajuster la hauteur
        }
      });
    });
  };

  // Fonction pour réinitialiser tous les champs de la modale
  const resetModal = useCallback(() => {
    setTitle('');
    setDate('');
    setContent([]);
    setTag('');
    setBannerFile(null);
  }, []);

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title || '');
      setDate(initialData.date || '');
      setContent(
        Array.isArray(initialData.content)
          ? initialData.content.map((block) => {
              if (block.type === 'image' && block.src) {
                return {
                  ...block,
                  src: `https://api.gemenu.fr${block.src}`, // Préfixer l'URL de base pour les images existantes
                };
              }
              return block;
            })
          : []
      );
      setTag(initialData.tag || '');
      setBannerFile(
        initialData.banner ? `https://api.gemenu.fr${initialData.banner}` : null
      );
    } else {
      resetModal();
    }
    adjustHeight();
  }, [isOpen, initialData, resetModal]);

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleAddBlock = (type) => {
    let newBlock;

    if (type === 'section') {
      newBlock = {
        type: 'section',
        title: '',
        date: '',
        content: '',
      };
    } else if (type === 'image') {
      newBlock = {
        type: 'image',
        src: '',
        alt: '',
      };
    }

    setContent([...content, newBlock]);
  };

  const handleChangeContent = (index, key, value) => {
    const updatedContent = [...content];
    updatedContent[index][key] = value;
    setContent(updatedContent);

    adjustHeight();
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBannerFile(file);
    }
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedContent = [...content];
      updatedContent[index] = {
        ...updatedContent[index], // Conserve les autres propriétés de l'objet, comme `alt`
        type: 'image',
        src: '', // On laisse vide pour que le backend puisse gérer la mise à jour du chemin
        file: file, // Ajoute le fichier pour le traitement dans `handleSave`
      };
      setContent(updatedContent);
      console.log('Updated content:', updatedContent);
    }
  };

  const handleSave = () => {
    if (!title || !date) {
      alert('Veuillez définir un titre et une date.');
      return;
    }

    if (!tag) {
      alert('Veuillez définir un tag.');
      return;
    }

    if (!content || content.length === 0) {
      alert("L'article doit contenir au moins un paragraphe ou une image.");
      return;
    }

    const articleData = {
      title,
      date,
      tag,
      content: content.map((block) => {
        if (block.type === 'image') {
          return {
            type: 'image',
            src: block.src || '', // Le chemin sera mis à jour par le backend
            alt: block.alt || '',
          };
        }
        return block;
      }),
      banner: bannerFile,
      images: content.filter((block) => block.type === 'image').map((block) => block.file),
    };

    console.log('Article data to be sent:', articleData);
    onSave(articleData);
    resetModal();
  };

  const handleClose = () => {
    setInitialData(null); // Réinitialiser les données initiales
    resetModal(); // Réinitialiser la modale avant de la fermer
    onClose(); // Fermer la modale
  };

  if (!isOpen) return null;

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal_article">
          <h2>{initialData ? "Modifier l'article" : 'Ajouter un article'}</h2>
          <div className="modal_article_preview">
            <div className="banner_uploader">
              {bannerFile && (
                <img
                  src={
                    bannerFile instanceof File
                      ? URL.createObjectURL(bannerFile) // Prévisualisation d'un fichier nouvellement sélectionné
                      : bannerFile // URL complète pour une bannière déjà existante
                  }
                  alt="Preview de la bannière"
                />
              )}
              <label htmlFor="banner-upload" className="banner_uploader_icon">
                <FontAwesomeIcon icon={faCloudUploadAlt} />
              </label>
              <input
                id="banner-upload"
                type="file"
                name="banner"
                accept="image/*"
                onChange={handleBannerChange}
              />
            </div>
            <div className="article_data">
              <textarea
                ref={(el) => (textareasRef.current[0] = el)}
                rows={1}
                className="adaptable" // Ajout de la classe adaptable pour cibler les textarea
                id="title"
                placeholder="Titre"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  adjustHeight();
                }}
              />
              <input
                id="date"
                type="date"
                value={date}
                onChange={handleDateChange}
              />
              <input
                id="tag"
                type="text"
                placeholder="Tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </div>
            <div className="content_editor">
              {content.map((block, index) => (
                <div key={index} className="content_editor_block">
                  {block.type === 'section' && (
                    <>
                      <textarea
                        rows={1}
                        ref={(el) => (textareasRef.current[index * 2 + 1] = el)}
                        className="content_editor_block_title adaptable"
                        placeholder="Écrivez un titre de section (optionnel)"
                        value={block.title}
                        onChange={(e) => {
                          handleChangeContent(index, 'title', e.target.value);
                          adjustHeight();
                        }}
                      />
                      <div className="content_editor_block_date">
                        <input
                          id={`block-date-${index}`}
                          type="date"
                          value={block.date || ''}
                          onChange={(e) => {
                            handleChangeContent(index, 'date', e.target.value);
                          }}
                        />
                        <label htmlFor={`block-date-${index}`}>date optionnelle</label>
                      </div>
                      <textarea
                        rows={1}
                        ref={(el) => (textareasRef.current[index * 2 + 2] = el)}
                        className="content_editor_block_textarea adaptable"
                        placeholder="Écrivez du texte..."
                        value={block.content}
                        onChange={(e) => {
                          handleChangeContent(index, 'content', e.target.value);
                          adjustHeight();
                        }}
                      />
                    </>
                  )}
                  {block.type === 'image' && (
                    <>
                      <input
                        type="file"
                        name="images"
                        accept="image/*"
                        onChange={(e) => handleFileChange(index, e)}
                      />
                      {block.src && (
                        <img 
                          src={block.src}
                          alt={block.alt}
                        />
                      )}
                      <input
                        type="text"
                        placeholder="Description de l'image"
                        value={block.alt}
                        onChange={(e) => handleChangeContent(index, 'alt', e.target.value)}
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
            <button onClick={() => handleAddBlock('section')}>Ajouter un paragraphe</button>
            <button onClick={() => handleAddBlock('image')}>Ajouter une image</button>
          </div>
          <button onClick={handleSave}>Enregistrer</button>
          <button onClick={handleClose}>Annuler</button>
        </div>
      </div>
    </div>
  );
}

export default ActualityModal;