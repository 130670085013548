import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ActualityCard from '../ActualityCard/ActualityCard';
import ActualityModal from '../ActualityModal/ActualityModal';

function ActualityFeed() {
  const [actualities, setActualities] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editingArticle, setEditingArticle] = useState(null);
  const { id } = useParams(); // Récupérer l'id de l'actualité
  const [logoutReason, setLogoutReason] = useState(null);

  useEffect(() => {
    const fetchActualities = async () => {
      try {
        const response = await fetch('https://api.gemenu.fr/api/articles');
        if (response.status === 403) {
          handleLogout('expired'); // Déconnecter l'utilisateur si le token est expiré
          return;
        }
        if (!response.ok) {
          throw new Error('Network response was not ok' );
        }
        const data = await response.json();
        // Tri des actualités par date de la plus récente à la plus ancienne
        const sortedActualities = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setActualities(sortedActualities);
      } catch (error) {
        console.error("Could not fetch actualities:", error);
      }
    };

    fetchActualities();
  }, []);

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (logoutReason) {
      console.log('Logout reason:', logoutReason);
    }
  }, [logoutReason]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';  // Empêche le défilement du body
    } else {
      document.body.style.overflow = 'auto';  // Rétablit le défilement du body
    }

    // Cleanup pour rétablir le défilement si le composant est démonté
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);

  const checkTokenValidity = async () => {
    try {
      const response = await fetch('https://api.gemenu.fr/api/auth/validate-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 403 || response.status === 401) {
        handleLogout('expired'); // Déconnecter l'utilisateur si le token est expiré
        return false;
      }

      return true;
    } catch (error) {
      console.error('Failed to validate token:', error);
      return false;
    }
  };

  const handleEdit = async (actuality) => {
    const isValid = await checkTokenValidity();
    if (isValid) {
      setEditingArticle(actuality);
      setShowModal(true);
    }
  };

  // Ajouter ou modifier un article, y compris la gestion de la bannière
  const handleSaveArticle = async (articleData) => {
    const formData = new FormData();
    formData.append('title', articleData.title);
    formData.append('date', articleData.date);
    formData.append('tag', articleData.tag);

    // Suivi des indices séparés pour le contenu et les images
    let imageIndex = 0;

    // Ajouter chaque bloc de contenu
    articleData.content.forEach((block, index) => {
        formData.append(`content[${index}][type]`, block.type);

        if (block.type === 'image') {
            formData.append(`content[${index}][src]`, block.src || '');
            formData.append(`content[${index}][alt]`, block.alt || '');

            // Ajouter le fichier d'image associé au même index
            if (articleData.images[imageIndex] && articleData.images[imageIndex] instanceof File) {
                formData.append(`content[${index}][file]`, articleData.images[imageIndex]);
            }
            imageIndex++; // Incrémenter l'index des images
        } else if (block.type === 'section') {
            formData.append(`content[${index}][title]`, block.title || '');
            formData.append(`content[${index}][date]`, block.date || '');
            formData.append(`content[${index}][content]`, block.content || '');
        }
    });

    // Gestion de la bannière
    if (articleData.banner && articleData.banner instanceof File) {
        formData.append('banner', articleData.banner);
    } else if (editingArticle && !articleData.banner && editingArticle.banner) {
        formData.append('banner', editingArticle.banner);
    } else if (editingArticle && !articleData.banner && !editingArticle.banner) {
        formData.append('banner', '');  // ou utiliser une clé spécifique pour indiquer la suppression
    }

    // Log du contenu de FormData pour vérification
    console.log('FormData content:');
    for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
    }

    // Envoi de la requête
    try {
        const method = editingArticle ? 'PUT' : 'POST';
        const url = editingArticle 
            ? `https://api.gemenu.fr:3001/api/articles/${editingArticle.id}` 
            : 'https://api.gemenu.fr:3001/api/articles';

        console.log('Request URL:', url);
        const response = await fetch(url, {
            method,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: formData,
        });

        if (response.ok) {
            const updatedArticle = await response.json();
            setShowModal(false);
            window.location.reload();

            setActualities((prevActualities) => {
                if (editingArticle) {
                    return prevActualities.map((article) =>
                        article.id === updatedArticle.id ? updatedArticle : article
                    );
                }
                return [...prevActualities, updatedArticle];
            });

            setEditingArticle(null);  // Réinitialise l'article en cours d'édition
        } else {
            console.error('Failed to save the article', response.statusText);
        }
    } catch (error) {
        console.error('Failed to save the article', error);
    }
  };

  const handleLogout = (reason = 'voluntary') => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setLogoutReason(reason);
    if (reason === 'expired') {
      window.alert('Votre session a expiré. Veuillez vous reconnecter.');
      window.location.reload();
    }
  };

  return (
    <div className="actuality_feed">
      <h1>Nos Actualités</h1>
      {isAuthenticated && (
        <div className='admin-actions'>
          <button onClick={setShowModal}>Ajouter un article</button>
          <button onClick={handleLogout}>Se Déconnecter</button>
        </div>
      )}
      {actualities.map((actuality, index) => (
        <ActualityCard
          key={actuality.id}
          actuality={actuality}
          // Le premier élément de la liste triée est marqué comme le plus récent
          isLatest={index === 0}
          initiallyExpanded={actuality.id === id} // Passer initiallyExpanded basé sur l'id récupéré
          onEdit={() => handleEdit(actuality)}
          handleLogout={handleLogout}
        />
      ))}
      <ActualityModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSaveArticle}
        initialData={editingArticle}
        setInitialData={setEditingArticle}
      />
      {!isAuthenticated && (
        <Link to="/login">Accès Réservé - Modifier actualités</Link>
      )}
    </div>
  );
}

export default ActualityFeed;