function Button({ content, target, onClick }) {
  const handleClick = (e) => {
      if(onClick) {
        e.preventDefault();  // Empêche l'action par défaut
        onClick();  // Exécute le gestionnaire onClick passé
      }
  };

  return (
    <a href={target} className="button" onClick={handleClick}>
      {content}
    </a>
  );
}

export default Button;