import React from 'react';
import Button from '../Utils/Button';

function CTABanner() {
  return (
    <div className="cta-banner">
      <div className='cta-banner_content'>
        <h3>Vous êtes médecin généraliste ou spécialisé ?</h3>
        <Button content='Continuez de vous former' target='/formation' />
      </div>
    </div>
  );
}

export default CTABanner;