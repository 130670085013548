import React from 'react';
import ActualityFeed from '../../components/ActualityFeed/ActualityFeed';

function Actuality() {
  return (
    <div className="content">
      <ActualityFeed />
    </div>
  );
}

export default Actuality;