import React from 'react';
import Button from '../Utils/Button';

function LastActualityCard({ title, image, tag, date, id}) {
  // Fonction utilitaire pour reformater la date
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  
  return (
    <div className="last-actuality_card">
      <div className="last-actuality_card_image">
        <img src={image} alt={title} />
      </div>
      <div className="last-actuality_card_content">
        <span className="last-actuality_card_tag">{tag}</span>
        <h3 className="last-actuality_card_title">{title}</h3>
        <div className="last-actuality_card_end">
          <Button content='En savoir plus' target={`/actuality#actuality-${id}`} />
          <div className="last-actuality_card_date">{formatDate(date)}</div>
        </div>
      </div>
    </div>
  );
}

export default LastActualityCard;