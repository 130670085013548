import React, { useEffect, useRef } from 'react';

function AddressAutocomplete() {
  const addressInputRef = useRef(null);
  const autocomplete = useRef(null); // Stocker l'autocomplete pour éviter de le réinitialiser à chaque focus.

  useEffect(() => {
    const inputElement = addressInputRef.current; // Copie de la référence actuelle pour utilisation stable dans l'effet.

    const handleScroll = () => {
      const pacContainer = document.querySelector('.pac-container');
      if (pacContainer && inputElement) {
        const rect = inputElement.getBoundingClientRect();
        pacContainer.style.top = `${rect.top + window.scrollY + inputElement.offsetHeight}px`;
        pacContainer.style.left = `${rect.left}px`;
      }
    };

    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      if (autocomplete.current && inputElement) {
        window.google.maps.event.clearInstanceListeners(inputElement);
      }
    };
  }, []);

  const handleFocus = () => {
    if (!autocomplete.current && addressInputRef.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(addressInputRef.current);
      autocomplete.current.setFields(['address_components', 'formatted_address']);

      autocomplete.current.addListener('place_changed', () => {
        const place = autocomplete.current.getPlace();
        console.log(place);
      });
    }
  };

  return (
    <input
      id='adresse'
      ref={addressInputRef}
      type="text"
      placeholder="Enter your address"
      onFocus={handleFocus}
    />
  );
}

export default AddressAutocomplete;