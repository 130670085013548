import React from 'react';
import Separator from '../Utils/Separator';
import CongresRegistration from '../CongresRegistration/CongresRegistration';

function CongresAbout() {
  return (
    <>
    <div className="congres-header">
      <h1 className="congres-header_title">Congrès GEMENU 2024 - Kinshasa RDC</h1>
      <h2 className="congres-header_subtitle">Un rendez-vous incontournable pour les experts en gériatrie, gérontologie et médico-social</h2>
      <h3 className="congres-header_date">25 - 28 septembre 2024 | Kinshasa, RDC</h3>
    </div>
    <Separator />
    <div className="congres-introduction">
      <h2 className="congres-introduction_title">Bienvenue au Congrès GÉMENU 2024</h2>
      <p className="congres-introduction_text">Le Congrès annuel organisé par l’association GÉMENU se tiendra cette année à Kinshasa, en République Démocratique du Congo. Ce rassemblement d’experts et de professionnels de la santé se concentre sur la gériatrie, la gérontologie, et les défis médico-sociaux en Afrique. Ce congrès, qui se déroulera du 25 au 28 septembre 2024, est une opportunité unique pour approfondir les connaissances, échanger des idées, et forger des collaborations fructueuses pour améliorer les soins de santé à travers le continent.</p>
    </div>
    <Separator />
    <div className="congres-programme">
      <h2 className="congres-programme_title">Programme du Congrès</h2>
      <div className="congres-programme-jour">
        <div className="congres-programme-jour_date">
          <span>25<br/>Septembre</span>
        </div>
        <div className="congres-programme-jour_text">
          <h3>Prévoyance médico-sociale</h3>
          <p>
            La journée inaugurale sera dédiée à la thématique de la prévoyance médico-sociale. Les participants auront l'occasion de découvrir une série de présentations en panel, suivies de discussions enrichissantes en petits groupes.
          </p>
        </div>
      </div>
      
      <div className="congres-programme-jour">
        <div className="congres-programme-jour_date">
          <span>26<br/>Septembre</span>
        </div>
        <div className="congres-programme-jour_text">
          <h3>La recherche</h3>
          <p>
            La deuxième journée du congrès sera axée sur les avancées en matière de recherche. Lors du colloque, les intervenants présenteront leurs travaux sous forme de conférences plénières, de sessions parallèles ou de posters. Des ateliers thématiques et des tables rondes seront également organisés pour favoriser les échanges entre les participants.
          </p>
        </div>
      </div>
      
      <div className="congres-programme-jour">
        <div className="congres-programme-jour_date">
          <span>27<br/>Septembre</span>
        </div>
        <div className="congres-programme-jour_text">
          <h3>Gériatrie</h3>
          <p>
            La troisième journée mettra l'accent sur la gériatrie. Les participants assisteront à des conférences et à des diaporamas, ainsi qu'à des expériences interactives et des discussions en tables rondes. Une conférence plénière viendra clôturer cette journée, offrant un espace de réflexion et de déclaration des principales conclusions du congrès.
          </p>
        </div>
      </div>
      
      <div className="congres-programme-jour">
        <div className="congres-programme-jour_date">
          <span>28<br/>Septembre</span>
        </div>
        <div className="congres-programme-jour_text">
          <h3>Ateliers Pratiques</h3>
          <p>
            La journée de clôture sera consacrée à des ateliers pratiques. Ces séances thématiques permettront aux participants de mettre en pratique les connaissances acquises lors des journées précédentes. Les résumés acceptés pour des présentations orales seront également discutés lors de ces sessions parallèles.
          </p>
        </div>
      </div>
    </div>
    <CongresRegistration />
  </>
  );
}

export default CongresAbout;