import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Congres from './pages/Congres/Congres';
import Actuality from './pages/Actuality/Actuality';
import Formation from './pages/Formation/Formation';
import Contact from './pages/Contact/Contact';
import Login from './pages/Login/Login';
import PrivacyAndLegal from './pages/PrivacyAndLegal/PrivacyAndLegal';
import Error from './pages/Error/Error';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './styles/main.scss';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/congres" element={<Congres />} />
          <Route path="/actuality" element={<Actuality />} />
          <Route path="/formation" element={<Formation />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-and-legal" element={<PrivacyAndLegal />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;