import React, { useState, useEffect } from 'react';
import Separator from '../Utils/Separator';

function Members() {
  const [members, setMembers] = useState([]);


  useEffect(() => {
    fetch('/members.json')
      .then(response => response.json())
      .then(data => {
        setMembers(data);
      })
      .catch(error => console.error('Erreur lors du chargement des membres:', error));
  }, []);

  return (
    <div className='members'>
      <Separator />
      <h2 className='members_title'>Nos membres</h2>
      <div className='members_cards'>
        {members.map((member) => (
          <div className='members_card' key={member.id}>
            <div className='members_card_image_filter'></div>
            <img src={member.picture} alt={`Membre ${member.name}`} className='members_card_image'/>
            <div className='members_card_content'>
              <h4 className='members_card_content_name'>{member.name}</h4>
              <p className='members_card_content_role'>{member.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Members;