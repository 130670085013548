import React from 'react';
import Separator from '../Utils/Separator';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer_mentions">
        <Link to="/privacy-and-legal#legal-notices">Mentions légales</Link>
        <a href="/privacy-and-legal">Politique de confidentialité</a>
        <Link to="/contact">Contactez nous</Link>
      </div>
      <Separator />
      <div className="footer_rights">
        <p>Copyright © 2024 Gemenu. Tous droits réservés.</p>
        <p>Propriété de Gemenu, conçu et développé par <a href="https://webwelder.fr">WebWelder</a></p>
      </div>
    </footer>
  );
}

export default Footer;