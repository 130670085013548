import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';

function Contact() {
  return (
    <div>
      <ContactForm />
    </div>
  );
}

export default Contact;