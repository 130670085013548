import React from 'react';
import { Link } from 'react-router-dom';

function Banner({ image, imagealt, title, subtitle, text }) {

  window.addEventListener('load', function() {
    const element = document.getElementById('animatedBanner');
    element.classList.add('slide-on-load');
  });

  return (
    <div className="banner">
      <img src={image} alt={imagealt} />
      <div className='banner_content' id='animatedBanner'>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <p>
          {text.map((line, index) => (
            <span key={index} className='date'>
              <span className="date-highlight">
                {line.split(':')[0]} 
              </span>
              {line.split(':')[1]}
              <br />
            </span>
          ))}
        </p>
        <div className='banner_content_buttons'>
          <Link to='/congres' className='banner_content_button'>En savoir plus</Link>
          <Link to='/congres#inscription' className='banner_content_button'>S'inscrire !</Link>
        </div>
      </div>
    </div>
  );
}

export default Banner;