import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginWrapper() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.gemenu.fr/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Identifiants incorrects');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);
      navigate('/actuality'); // Redirige vers la page d'actualités
    } catch (err) {
      setError('Échec de la connexion. Vérifiez vos informations d\'identification.');
    }
  };

  return (
    <div className="login_wrapper">
      <div className="login_form">
        <h2>Accès Admin</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Se connecter</button>
        </form>
      </div>
    </div>
  );
}

export default LoginWrapper;