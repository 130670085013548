import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    objet: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.gemenu.fr/api/forms/submit-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
      if (response.ok) {
        alert(responseData.message);
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      alert('Erreur lors de l\'envoi du formulaire: ' + error.message);
    }
  };

  return (
    <div className="contact">
      <h1>Une question ?</h1>
<form className="contact_form" onSubmit={handleSubmit}>
        <label htmlFor="nom">Nom:</label>
        <input type="text" id="nom" name="nom" required onChange={handleChange} value={formData.nom} />

        <label htmlFor="prenom">Prénom:</label>
        <input type="text" id="prenom" name="prenom" required onChange={handleChange} value={formData.prenom} />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required onChange={handleChange} value={formData.email} />

        <label htmlFor="telephone">Téléphone:</label>
        <input type="tel" id="telephone" name="telephone" required onChange={handleChange} value={formData.telephone} />
        
        <div className='contact_form_text-and-submit'>
          <label htmlFor="objet">Objet:</label>
          <input type="text" id="objet" name="objet" required onChange={handleChange} value={formData.objet} />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" required onChange={handleChange} value={formData.message}></textarea>

          <button type="submit">Envoyer</button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;