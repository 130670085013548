import React from "react";
import Banner from "../../components/Banner/Banner";
import About from "../../components/About/About";
import CTABanner from "../../components/CTABanner/CTABanner";
import LastActuality from "../../components/LastActuality/LastActuality";
import Members from "../../components/Members/Members";
import Partners from "../../components/Partners/Partners";

function Home() {
  return (
    <>
      <Banner
        image="assets/banner_pictures/banner_congres.png"
        imagealt="Bannière du Congrès Gemenu"
        title="Congrès Gemenu"
        subtitle="Du 25 au 28 septembre 2024"
        text={[
          "25 septembre : Prévoyance médico-sociale",
          "26 septembre : Recherche",
          "27 septembre : Gériatrie",
          "28 septembre : Ateliers pratiques"
        ]}
      />
      <About />
      <CTABanner />
      <LastActuality />
      <Members />
      <Partners />
    </>
  );
}

export default Home;