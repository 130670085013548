import { React, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

function Header() {

  window.addEventListener('load', function() {
    const element = document.getElementById('animatedHeader');
    element.classList.add('fall-on-load');
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const toggleMenu = () => {
          setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <header className="header" id="animatedHeader">
      <Link to='/' className='header_logo'>
        <img src="/assets/logo_gemenu.png" alt="Logo de l'association Gemenu"/>
      </Link>
      <nav className='header_nav'>
        <Link to='/congres' className='header_nav_link'>Congrés</Link>
        <Link to='/formation' className='header_nav_link'>Formation</Link>
        <Link to='/actuality' className='header_nav_link'>Actualités</Link>
        <Link to='/contact' className='header_nav_link'>Contact</Link>
      </nav>
      <div className="header_burger">
        <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
        <span className={`header_burger_menu_shadow ${isMenuOpen ? 'open' : ''}`}/>
        <div className={`header_burger_menu ${isMenuOpen ? 'open' : ''}`}>
          <FontAwesomeIcon icon={faXmark} onClick={toggleMenu} />
          <nav className='header_burger_menu_nav'>
            <Link to='/congres' className='header_burger_menu_nav_link' onClick={toggleMenu}>Congrés</Link>
            <Link to='/formation' className='header_burger_menu_nav_link' onClick={toggleMenu}>Formation</Link>
            <Link to='/actuality' className='header_burger_menu_nav_link' onClick={toggleMenu}>Actualités</Link>
            <Link to='/contact' className='header_burger_menu_nav_link' onClick={toggleMenu}>Contact</Link>
          </nav>
        </div>
    </div>
    </header>
  );
}
  
export default Header;